<!-- 这是礼包购买面板 -->
<template>
    <div>
        <van-collapse v-model="val1.activeIdx" accordion>
            <van-collapse-item v-for="(item2, index2) in giftPackageList" :key="index2" :name="index2">
                <template #title>
                    <div class="gift_subset_top">
                        <div class="gift_subset_titles">
                            <div class="gift_name">
                                <img src="../assets/imgs/gift.png" alt="">
                                <div :class="['name', lang===2?'ltr':'']">&nbsp;&nbsp;{{lang===2 ? ` ${$t('m.usd')} ` + item2.objective : item2.objective + ` ${$t('m.usd')} ` }} {{ $t('m.offerText') }}&nbsp;&nbsp;</div>
                            </div>
                            <div :class="`recharge_row ${ifRtl}`">
                                <span class="text">{{ $t('m.offerneed') }}&nbsp;</span>
                                <span v-if="utils.checkTokenauth(userInfo) === 0">{{item2.objective + ' USD '}}</span>
                                <span v-else class="num">
                                    {{ JSON.stringify(item2.user_buy_info) === '{}' ? item2.objective + ' USD ' : item2.user_buy_info.rate_of_progress + '/' + (JSON.stringify(item2.user_buy_info) === '{}' ? item2.objective : item2.user_buy_info.objective) + ' USD ' }}
                                    &nbsp;<span @click="gotoCurgiftpageRechargerecords(item2)" v-if="item2.user_buy_info.is_status === 0">></span>&nbsp;
                                </span>
                            </div>
                            <div :class="`recharge_row ${ifRtl}`">
                                <span class="text">{{ $t('m.expiredDate') }}&nbsp;
                                    <span class="big_text no_buy" v-if="(item2.status === 1 && JSON.stringify(item2.user_buy_info) === '{}') || utils.checkTokenauth(userInfo) === 0">{{ item2.valid_day }} {{ item2.valid_day <= 1 ? $t('m.day') : $t('m.days') }} </span>
                                    <span class="big_text has_buy" v-else-if="JSON.stringify(item2.user_buy_info) !== '{}'">
                                        <van-count-down class="djs_text" :time="item2.countdownTime" :format="`DD ${$t('m.days')} HH:mm:ss`" @change="(event)=>{return change(event, item2)}" @finish="finish"/>&nbsp;
                                     </span>
                                </span>
                            </div>
                        </div>
                        <!-- 右侧购买礼包按钮（冻结、未购买、 已购买、校验账号(1:已校验  0：未校验) ----非冻结状态下）  -->
                        <div class="gift_subset_titles_right">      
                            <!-- checkTokenauth() 1:已校验（未过期）  0:未校验（已过期）
                                 is_status 0-正在进行 1-已完成 2-已过期 (当前礼包状态)
                                 no_login  已购买礼包未验证身份 -->
                            <div class="no_login" v-if="utils.checkTokenauth(userInfo) === 0" @click="checkLogin" v-html="item2.ifClaim ? $t('m.text1-7') : $t('m.text2-7')"></div>
                            <!-- 已验证身份未领取 - 显示免费领取 -->
                            <div class="opt_part_noclaim" v-else-if="JSON.stringify(item2.user_buy_info) === '{}'" @click="confirmFun(item2, 1, '')">
                                 <span class="go_claim">{{ $t('m.text3-7') }} </span>
                            </div>
                            <!-- 已验证身份已领取 - 显示进行中 -->
                            <div class="opt_part" v-else-if="item2.user_buy_info.is_status === 0">
                                <span class="status_text" @click="showgorechargeTips">{{ $t('m.going')}} &nbsp;</span>
                            </div>
                        </div>
                    </div>
                    <div class="gift_subset_bottom">
                        <div :class="['recharge_can_get', lang === 2 ? 'text_r' : 'text_l']" v-if="JSON.stringify(item2.user_buy_info) === '{}'">{{ $t('m.compltedtask') }} {{ item2.coins }}</div>
                        <div :class="['recharge_can_get', lang === 2 ? 'text_r' : 'text_l']" v-else>{{ $t('m.compltedtask') }} {{ item2.user_buy_info.coins }}</div>
                    </div>
                </template>

                <!-- 礼包下对应充值数额金币子集(上个版本的，展示每个下面的可充值金币) -->
                <!-- <ul v-if="item2.user_buy_info.is_status === 0">
                    <div class="gift_recharge_coins">
                        <div class="gift_recharge_li" v-for="(giftItem, giftId) in item2.child" :key="giftId">
                            <div class="gift_recharge_left">
                                <img class="coins_img" src="../assets/imgs/coins.png" alt="">
                                <span class="coins_num">
                                    {{ giftItem.origin_coins }}
                                    <span class="add_coin" v-if="giftItem.num > 0">+{{ giftItem.num }}</span>
                                </span>
                            </div>
                            <div class="gift_recharge_right" @click="showConfirmpop(item2, 2, giftItem)"> 
                                 <span class="forzen" v-if="globalData.ifRechargespecialday">{{ $t('m.frozen') }}</span> 
                                 <span class="text" > {{ currency }} {{ giftItem[currency.toLowerCase()] }}</span>
                            </div>
                        </div>
                    </div>
                </ul> -->
                
            </van-collapse-item>
        </van-collapse>

        <changegiftPackpop v-if="ifShowchangePop" 
        :lang="lang"
        :claimStatus="claimStatus"
        :giftpayShow="ifShowchangePop"
        @confirmFn="showConfirmpop"
        @closePop="closechangePop"></changegiftPackpop>
    </div>
</template>

<script>
import {queryOffercheck} from '../assets/js/request'
export default ({
    components: {
        changegiftPackpop: () => import('../components/pop/changegiftpackPop.vue')
    },
    props: {        
        lang: {
            type: Number,
            default: 1
        },
        djsIsover: {
            type: Boolean,
            default: false
        },
        differenceTime: {
            type: Number,
            default: 0
        },
        val1: {
            type: Object,
            default: {}
        },
        userInfo: {
            type: Object,
            default: {}
        },
        // ifRtl: {
        //     type: String,
        //     default: ''
        // },
        currency: {
            type: String,
            default: 'USD'
        },
    },
    filters: {
        // filterUnit(value){
        //   return this.utils.unitChange(value, 'coins', 1)
        // }
    },
    data() {
        return {
            claimStatus: 0, //礼包领取状态 0： 未领取  1：已领取
            ifShowchangePop: false,
            ifVerify: Number(this.globalData.userINFO.ifVerify),
            giftPackageList: [] , //礼包列表数据
            statusTypes: {
                2: this.$t('m.curreny'),
                3: this.$t('m.frozen')
            },
            payData: {},
            // lang: Number(sessionStorage.getItem('lang')),
            giftItem:"",
            type:"",
            goodsItem:"",
        }
    },
    computed:{
        ifRtl(){
            return Number(this.lang) === 2 ? 'rtl text_r' : 'text_l'
        }
    },
    created(){
        console.log('------------------------- 礼包购买组件(giftSubsetpanel.vue) ---------------------------------------')
        ////console.log(this.globalData.serverTime, '...............服务器时间')
        console.log('用户是否登录............', this.utils.checkTokenauth(this.userInfo))
        console.log('倒计时时间（countdownTime）............', this.differenceTime)
        console.log('父组件传过来的语言..............', this.lang)
        console.log('ifRtl.............', this.ifRtl)
        console.log('this.$i18n.locale.............', this.$i18n.locale)
        console.log(JSON.parse(JSON.stringify(this.val1)), '...............当前选中的礼包时间类别')
        ////console.log(this.globalData, '...............全局数据')
        //console.log(this.userInfo, '...........userInfo')

        this.giftPackageList = this.val1.children[0]
        this.giftPackageList.forEach(item => {
            item.countdownTime = JSON.stringify(item.user_buy_info) !== '{}' ? item.user_buy_info.end_time * 1000 - this.globalData.serverTime * 1000 : 0
            // item.countdownTime = this.differenceTime
            if(JSON.stringify(item.user_buy_info) !== ''){
                this.utils.unitChange(item.user_buy_info, 'coins', 1)
            }
            // ////////console.log('end_time: ',item.user_buy_info.end_time, 'serverTime: ', this.globalData.serverTime)
            // let TIME = this.utils.calculateTimeDifference(item.user_buy_info.end_time, this.globalData.serverTime)
            // let {days, hours, minutes, seconds} = TIME
            // item.frozenTime = days + ` ${this.$t('m.days')} ` + '' + hours + ':' + minutes + ':' + seconds
        })

        console.log(JSON.parse(JSON.stringify(this.giftPackageList)), '............giftSubsetpanel.vue 中 giftPackageList')
    },
    methods: {
        closechangePop(){
            this.ifShowchangePop = false
        },
        change(event, item){
            // ////////console.log(event, '..............倒计时change')
            // ////////console.log(JSON.parse(JSON.stringify(item)), '..............item')
            let {days, hours, minutes, seconds} = event
            item.djsIsover = days === 0 && hours === 0 && minutes === 0 && seconds === 0 ? true : false
        },
        //倒计时结束
        finish(event){
            ////////console.log(event, '..............倒计时over')
        },
        //校验登录
        checkLogin(){
            //请先输入账号id
            let _rid = sessionStorage.getItem('rid')
            if(_rid === "" || this.globalData.rid === "") {
                this.$toast(this.$t('m.nologinTips'))
                return false
            }
            this.$emit('checkloginfn', {opt: 1, type: 1})
        },
        //点击免费领取礼包
        confirmFun(_giftItem, _type, _goodsItem){
            this.giftItem = _giftItem
            this.type = _type
            this.goodsItem = _goodsItem

            let {user_buy_info} = this.globalData.curUserbuyinfo
            let _ifEmpty = this.utils.isEmptyObject(user_buy_info)          
            this.queryGiftpackstatus()
        },
        //查询是否有正在进行中的礼包，如果有需要提示用户更换礼包
        queryGiftpackstatus(){
            queryOffercheck({uid: this.userInfo.uid}).then(res => {
                console.log(res, '...............giftSubsetpanel.vue 查询是否有正在进行中的礼包')
                let {is_offer} = res.data
                if(is_offer===1){
                    this.claimStatus= 1
                    console.log('有正在进行中的礼包.........')
                    this.ifShowchangePop = true
                    sessionStorage.setItem('giftpackClaimStatus', 1)
                } else {              
                    console.log('没有正在进行中的礼包........')          
                    this.showConfirmpop()
                    sessionStorage.setItem('giftpackClaimStatus', 0)
                }
            }).catch(err => {
                console.log(err, '................查询是否有正在进行中的礼包异常')
            })
        },
        /**
         * 用户领取礼包信息确认弹窗
         * @param giftItem   构建订单所需要的参数
         * @param type       1:买礼包  2：礼包下金币充值
         * @param goodsItem  礼包下充值金币的item值
        */
        showConfirmpop(){
            console.log('giftSubsetpanel.vue文件礼包购买面板showConfirmpop方法............')
            let {giftItem, type, goodsItem} = this
            
            //console.log(this.globalData, '..........globalData')
            //console.log('购买按钮点击显示的值giftItem.................', JSON.parse(JSON.stringify(giftItem)))
            //console.log('购买按钮点击显示的值goodsItem.................', JSON.parse(JSON.stringify(goodsItem)))

            let _rid = sessionStorage.getItem('rid')
            //请先输入账号id
            if(_rid === "" || this.globalData.rid === "") {
                this.$toast(this.$t('m.nologinTips'))
                return false
            }

            //礼包已过期请刷新页面重试
            if(giftItem.djsIsover && giftItem.djsIsover !== undefined) {
                this.$toast(this.$t('m.refrsehTips'))
                return false
            }

            //登录账号过期，重新校验登录
            let _ifExipred = this.utils.checkTokenauth(this.userInfo)
            //////console.log(_ifExipred, '........._ifExipred')
            if(_ifExipred === 0) {
                this.checkLogin()
                return false
            }

            giftItem.rechargeCoinsinfo = {...goodsItem}

            //显示领取弹窗信息确认
            console.log('giftSubsetpanel.vue文件礼包购买面板showConfirmpop方法......显示领取弹窗信息二次确认')
            this.$emit('handlershowRechargePop', {val: giftItem, type})
        },
        //去当前礼包充值记录页面
        gotoCurgiftpageRechargerecords(item){
            //////console.log(JSON.parse(JSON.stringify(item), '........去当前礼包充值记录页面item'))
            this.$router.push({path: '/giftpackRechargerecords', query: {offer_order_id: item.user_buy_info.offer_order_id}})
        },
        showgorechargeTips(){
            //判断礼包是否过期
            let {curUserbuyinfo:{user_buy_info}, serverTime} = this.globalData
            //console.log(user_buy_info, serverTime, this.djsIsover)
            if(!this.djsIsover) {
                this.$toast(this.$t('m.gotorecharge'))
            } else {
                this.$toast(this.$t('m.giftpackexpiredTips'))
            }
        }
    }
})
</script>

<style lang="scss" scoped>
@import '../assets/css/common.scss';
.gift_recharge_coins {
    .gift_recharge_li {
        border-bottom: 1px solid #dbdbdb;
        width: 100%;
        padding: 21px 0;
        @include flex-between;
        .gift_recharge_left {
            width: 70%;
            @include flex-row-left-center;
            .coins_img {
                width: 24px;
                height: 24px;
                margin: 0 8px;
            }
            .coins_num {
                color: #333;
                font-size: 14px;

                .add_coin {
                    color: #40a9ff;
                    font-size: 13px;
                }
            }
            .number {
                font-size: 14px;
                font-weight: 600;
                color: #333333;
                line-height: 16px;
            }
        }
        .gift_recharge_right {
            width: 30%;
            height: 48px;
            background: #FFFFFF;
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            border: 1px solid #333333;
            @include flex-column-cen;
            .text {
                font-size: 13px;
                font-weight: 600;
                color: #333333;
                line-height: 15px;
            }
        }
    }
}
</style>
